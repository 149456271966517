.inputForm {
    @apply flex-col items-center self-stretch ;
}

.inputDiv {
    @apply relative w-full;
}

.fieldIcon {
    @apply absolute left-2.5 top-3.5 ;
}

.LgInputFieldIcon {
    @apply absolute left-2.5 top-2.5 ;
}

.LgInputDiv {
    @apply relative w-full;
}

.LgInputTextArea {
    @apply w-full p-2.5 pl-10 h-40 rounded-md text-body placeholder-greyscale-450 bg-greyscale-650 text-white font-inter border-greyscale-600 focus:outline-none ;
}

.inputTextArea {
    @apply  w-full p-2 pl-10 h-10 mt-1 rounded-md text-body placeholder-greyscale-450 bg-greyscale-650 text-white font-inter border-greyscale-600 focus:outline-none;
}

/* Utility classes for dropdown menus */

.dropdownForm {
    @apply flex h-10 p-3 justify-between items-center gap-2 rounded-md shadow-[#1018280D] border-greyscale-600 bg-greyscale-650 ;
}

.Selector {
    @apply flex px-2 py-1.5 items-center gap-2 self-stretch rounded-lg border-greyscale-600 bg-greyscale-650 ;
}

.InputState {
    @apply flex flex-1 flex-shrink-0 px-3 py-3 items-center gap-2 self-stretch border-x border-y rounded-lg border-teal-900 bg-teal-900  ;
}

/* The following applies to two input fields within the same flex-row with equal spacing */

.RowInputDiv {
    @apply relative flex flex-grow flex-shrink-0 basis-0 h-11 p-3 items-center gap-2 bg-greyscale-650 rounded-md ;
}

.HalfInputField {
    @apply  w-full pl-8 h-10 rounded-md text-body placeholder-greyscale-450 bg-transparent text-white font-inter border-none focus:outline-none ;
}

/* Search Bar Utility classes */

.SearchBarBorder {
    @apply  flex flex-row h-10 p-3 items-center gap-2 rounded-lg border-greyscale-600 border-[1px] bg-greyscale-650 ;
}

.InnerSearchDiv {
    @apply flex flex-row p-0 items-center gap-1.5 w-full rounded-none ;
}

.SearchInput {
    @apply  overflow-ellipsis font-inter font-normal text-base not-italic placeholder-greyscale-450 w-full;
}