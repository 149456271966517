.btn {
    @apply font-normal inline-flex items-center justify-center rounded-full ;
  }
  
  /* Define the primary button color */
  .btn-primary {
    @apply bg-teal-500  border-teal-500 text-greyscale-100 active:bg-teal-700 active:border-teal-600 disabled:bg-greyscale-700 disabled:text-greyscale-450 disabled:cursor-not-allowed disabled:border-greyscale-700 ; 
  }
  /* Define the secondary button color */
  .btn-secondary {
    @apply bg-teal-900 text-teal-100 border-teal-900 border-2 active:bg-teal-800 disabled:bg-greyscale-700 disabled:text-greyscale-450 disabled:cursor-not-allowed disabled:border-greyscale-700;
  }

  /* Define the disabled button color */
  .btn-disabled {
    @apply text-greyscale-450 bg-greyscale-700  border-greyscale-700 pointer-events-none cursor-not-allowed;
  }
  
  /* Define different sizes for buttons */
  .btn-xs{
    @apply text-xs font-inter px-3.5 py-2 space-x-2;
  }

  .btn-sm {
    @apply text-sm font-inter px-3.5 py-1.5 space-x-2;
  }
  .btn-md {
    @apply text-base font-inter py-2.5 px-4 space-x-2 ;
  }
  .btn-lg {
    @apply text-lg font-inter px-4 py-2.5 space-x-2;
  }
  .btn-xl {
    @apply text-xl font-inter px-5 py-3 space-x-2;
  }

  /* Settings buttons. i.e "Your Account"; "Bank Account and Location" etc. */

.settings-btn-xxl {
  @apply flex p-4 my-4 items-center justify-between w-full gap-4 self-stretch rounded-2xl border-greyscale-600 bg-greyscale-700 ;
}

.settings-btn-content {
  @apply flex-col items-start gap-2 self-stretch ;
}

.settings-btn-title {
  @apply font-ubuntu text-left font-medium not-italic leading-5 mb-0.5 text-greyscale-150 text-sm  ;
}

.settings-btn-description {
  @apply font-inter text-sm font-normal text-left leading-4 not-italic text-greyscale-400 ;
}