@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@400;500;700&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;
@import "~inter-ui/inter.css";
@import "/src/styles/components/buttons.css";
@import "/src/styles/components/inputs.css";
body {
    margin: 0;
    font-family: 'Inter', sans-serif;
    -moz-osx-font-smoothing: grayscale;
    color: white;
    background-color: #000000;
}

button {
    border-style: solid;
}

input {
    border-style: solid;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

p {
    color: white;
    font-family: 'Inter', sans-serif;
}

.wishlist-icon-me {
    width: 17px;  /* adjust to desired width */
    height: 17px; /* adjust to desired height */ 
    margin-right: 0.5rem; 
    fill: white; 
  }
  
  .active-tab-style {
    background-color:#062521; 
    border-color: #D2F1ED;
    color: #D2F1ED
  }

.horizontal-scroll::-webkit-scrollbar {
    display: none;
}

.horizontal-scroll {
    display: flex;
    white-space: nowrap;
    overflow-x: auto;
    /* IE and Edge */
    -ms-overflow-style: none;
    /* Firefox */
    scrollbar-width: none;
}

/* Container for the dropdown suggestions */
.pac-container {
    border: #2C2C2E 1px solid ;
    border-radius: 10px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
    font-family: Inter, sans-serif;
    background-color: #1C1C1E;
    
  
}
.modal-enter {
    transform: translateY(100%);
    transition: transform 1s ease-out;
  }
  
  .modal-enter-active {
    transform: translateY(0);
  }

.pac-matched {
    font-weight: bold;
} 

/* Individual item in the dropdown */
.pac-item {
    padding: 0.2rem 0.2rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    color: #ffffff;
    border: #2C2C2E 1px solid ; 
}

.pac-item-query {
    color: #ffffff;
} 
.hide-scrollbar::-webkit-scrollbar {
    display: none;  /* for Chrome, Safari, and Opera */
  }
  
  .hide-scrollbar {
    -ms-overflow-style: none;  /* for Internet Explorer and Edge */
    scrollbar-width: none;  /* for Firefox */
  }
