@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url('https://fonts.googleapis.com/css?family=Ubuntu:regular,bold,italic&subset=latin,latin-ext');
:root {
	--header-height: 64px;
}

.App {
	text-align: center;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #000000;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

.ant-skeleton.ant-skeleton-element {
	height: 100% !important;
	width: 100% !important;
}

.ant-btn-link:active {
    color: #126F63 !important; 
	/* or whichever color you want for active state */
}

.ant-btn-link:visited {
    color: #1EB9A5 !important; 
}

.ant-dropdown-menu {
	max-height: 250px;
	overflow: auto;
	border: white;
}

.floating-button {
	position: fixed;
	bottom: 20px;
	right: 20px;
	background: none;
}


.yc-auto-shown-popup {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 100vh;
}

.swipeActionDelete {
    @apply bg-red-500 text-white flex items-center justify-center p-4;
}





